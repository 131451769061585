<template>
  <div>
    <panel-description
      :paymentMethodName="paymentMethodName"
      :virtualAccountNumber="virtualAccountNumber"
      :viewMybookingUrl="viewMybookingUrl"
      :price="price"
      :orderNumber="orderNumber"
    />
    <div class="container py-3">
      <countdown :paymentDueDiff="paymentDueDiff" class="mt-4"></countdown>
      <panel-steps
        :content="content"
        :viewMybookingUrl="viewMybookingUrl"
        v-if="content"
      ></panel-steps>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Countdown from '@/components/how-to-pay/partials/countdown';
import PanelDescription from '@/components/how-to-pay/partials/panel-description';
import PanelSteps from '@/components/how-to-pay/partials/panel-steps';
export default {
  name: 'bni',
  components: {
    PanelDescription,
    Countdown,
    PanelSteps,
  },
  props: [
    'paymentMethodName',
    'price',
    'virtualAccountNumber',
    'viewMybookingUrl',
    'orderNumber',
    'paymentDueDiff',
  ],
  data: () => ({
    content: null,
  }),
  computed: {
    ...mapState({
      bookingOrder: (state) => state.v2.booking.bookingOrder,
    }),
  },
  mounted() {
    this.content = {
      tabs: [
        {
          title: 'via ATM BNI / Bersama',
          steps: [
            this.$t('booking.guide.bniBersama.msg1'),
            this.$t('booking.guide.bniBersama.msg2'),
            this.$t('booking.guide.bniBersama.msg3'),
            this.$t('booking.guide.bniBersama.msg4'),
            `${this.$t('booking.guide.bniBersama.msg5')} ${this.virtualAccountNumber}`,
            this.$t('booking.guide.bniBersama.msg6'),
            this.$t('booking.guide.bniBersama.msg7'),
            this.$t('booking.guide.bniBersama.msg8'),
          ],
        },
        {
          title: 'via Internet Banking BNI',
          steps: [
            this.$t('booking.guide.bniInternet.msg1'),
            this.$t('booking.guide.bniInternet.msg2'),
            this.$t('booking.guide.bniInternet.msg3'),
            this.$t('booking.guide.bniInternet.msg4'),
            `${this.$t('booking.guide.bniInternet.msg5')} ${this.virtualAccountNumber} ${this.$t(
              'booking.guide.bniInternet.msg6',
            )}`,
            this.$t('booking.guide.bniInternet.msg7'),
            this.$t('booking.guide.bniInternet.msg8'),
            this.$t('booking.guide.bniInternet.msg9'),
          ],
        },
        {
          title: 'via Mobile Banking BNI',
          steps: [
            this.$t('booking.guide.bniMobile.msg1'),
            this.$t('booking.guide.bniMobile.msg2'),
            this.$t('booking.guide.bniMobile.msg3'),
            `${this.$t('booking.guide.bniMobile.msg4')} ${this.virtualAccountNumber} ${this.$t(
              'booking.guide.bniMobile.msg5',
            )}`,
            this.$t('booking.guide.bniMobile.msg6'),
            this.$t('booking.guide.bniMobile.msg7'),
            this.$t('booking.guide.bniMobile.msg8'),
          ],
        },
        {
          title: 'via Internet Banking Bank lain',
          steps: [
            this.$t('booking.guide.bniOther.msg1'),
            this.$t('booking.guide.bniOther.msg2'),
            `${this.$t('booking.guide.bniOther.msg3')} ${this.virtualAccountNumber} ${this.$t(
              'booking.guide.bniOther.msg4',
            )}`,
            this.$t('booking.guide.bniOther.msg5'),
            this.$t('booking.guide.bniOther.msg6'),
            this.$t('booking.guide.bniOther.msg7'),
          ],
        },
      ],
    };
  },
};
</script>

<style scoped></style>
